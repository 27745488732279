import React from 'react';
import './App.css';
import StickyHeadTable from "./Table/StickyHeadTable";

function App() {
  return (
    <div className="App">
    <StickyHeadTable/>
    </div>
  );
}

export default App;
