import React from "react";

import { Checkbox, Stack, TextField } from "@mui/material";

type propTypes = {
  inputType: string;
  name: string;
  value: string | boolean;
  handleChange: (field: string, value: string | number | boolean) => void;
};

function InputField({ inputType, name, value, handleChange }: propTypes) {
  const handleInputChange = (value: string | number | boolean) => {
    return handleChange(
      name,
      inputType === "number" ? parseFloat(value as string) : value
    );
  };

  if (inputType === "checkbox") {
    return (
      <Checkbox
        checked={value as boolean}
        onChange={(event, checked) => {
          handleInputChange(checked);
        }}
      />
    );
  } else if (inputType === "date") {
    return (
      <Stack>
        <TextField
          id="datetime-local"
          type="datetime-local"
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            handleInputChange(event.currentTarget.value);
          }}
        />
      </Stack>
    );
  } else if (inputType === "number") {
    return (
      <TextField
        id="outlined-number"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          handleInputChange(event.currentTarget.value);
        }}
      />
    );
  } else {
    return (
      <TextField
        id={name}
        value={value}
        onChange={(event) => {
          handleInputChange(event.currentTarget.value);
        }}
        fullWidth
      />
    );
  }
}

export default InputField;
