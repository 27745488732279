import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import InputField from "./InputField";
import { useState } from "react";
import styles from "./Table.module.css";

type TableDetailsType = {
  EventName: string;
  Prequeue: boolean;
  Start: string;
  End: string;
  Suspend: boolean;
  TotalActiveUsers: string;
  NewUsersPerMin: string;
  SessionDuration: string;
  SessionRenewal: string;
  QueuingMethod: string;
  HTMLTemplate: string;
};

const initialTableDetails: TableDetailsType = {
  EventName: "",
  Prequeue: false,
  Start: "",
  End: "",
  Suspend: false,
  TotalActiveUsers: "",
  NewUsersPerMin: "",
  SessionDuration: "",
  SessionRenewal: "",
  QueuingMethod: "",
  HTMLTemplate: "",
};

type tableFieldType = {
  label: string;
  name: keyof TableDetailsType;
  inputType: "text" | "number" | "date" | "checkbox" | "longText";
};

const tableFields: tableFieldType[] = [
  { label: "Event Name", name: "EventName", inputType: "text" },
  { label: "Prequeue", name: "Prequeue", inputType: "checkbox" },
  { label: "Start", name: "Start", inputType: "date" },
  { label: "End", name: "End", inputType: "date" },
  { label: "Suspend", name: "Suspend", inputType: "checkbox" },
  {
    label: "Total active users",
    name: "TotalActiveUsers",
    inputType: "number",
  },
  { label: "New users per min", name: "NewUsersPerMin", inputType: "number" },
  { label: "Session Duration", name: "SessionDuration", inputType: "number" },
  { label: "Session Renewal", name: "SessionRenewal", inputType: "text" },
  { label: "Queuing Method", name: "QueuingMethod", inputType: "text" },
  { label: "HTML Template", name: "HTMLTemplate", inputType: "longText" },
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableRows, setTableRows] = useState([initialTableDetails]);

  const handleChange = (
    field: string,
    value: string | number | boolean,
    index: number
  ) => {
    const updatedTableRows = tableRows.map((rowDetails, i) =>
      i === index
        ? {
            ...rowDetails,
            [field]: value,
          }
        : rowDetails
    );
    setTableRows(updatedTableRows);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const removeRow = (index: number) => {
    const filteredRows = tableRows.filter((row, i) => i !== index);
    setTableRows(filteredRows);
  };

  const addRow = () => {
    setTableRows([...tableRows, initialTableDetails]);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: "calc(100vh - 60px)" }}>
        <div className={styles.header}>
          <div className={styles.menuButton}>
            <Button variant="contained" endIcon={<SendIcon />}>
              Send
            </Button>
          </div>
          <div>
            <Button variant="outlined" onClick={addRow}>
              Add row
            </Button>
          </div>
        </div>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ background: "#fff" }}>
            <TableRow>
              {tableFields.map((tableField, index) => {
                return (
                  <TableCell key={"columnName" + index} sx={{ top: "60px" }}>
                    <h2>{tableField.label}</h2>
                  </TableCell>
                );
              })}
              <TableCell sx={{ top: "60px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowDetails, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={"tablerow" + index}
                >
                  {tableFields.map((tableField, i) => (
                    <TableCell key={"tableCell" + index + "-" + i}>
                      <InputField
                        inputType={tableField.inputType}
                        name={tableField.name}
                        value={rowDetails[tableField.name]}
                        handleChange={(name, value) =>
                          handleChange(name, value, index)
                        }
                      />
                    </TableCell>
                  ))}
                  <TableCell>
                    <Button
                      className={styles.deleteButton}
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => removeRow(index)}
                    >
                      Delete row
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
